@keyframes pulse {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
  
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
  
  .animate-pulse {
    animation: pulse 2s infinite;
  }